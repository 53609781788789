const categoriesData = [
  {
    title: "Home Appliances",
    items: [
      { name: "TV", imageUrl: "/images/tv.jpg" },
      { name: "Washing machine", imageUrl: "/images/washing-machine.jpg" },
      { name: "Bluetooth speaker", imageUrl: "/images/bluetooth-speaker.jpg" },
      { name: "Air Conditioner", imageUrl: "/images/air-conditioner.jpg" },
      { name: "Ironing stand", imageUrl: "/images/ironing-stand.jpg" },
      {
        name: "Clothes drying stand",
        imageUrl: "/images/clothes-drying-stand.jpg",
      },
      { name: "Refrigerator", imageUrl: "/images/refrigerator.jpg" },
    ],
  },
  {
    title: "Kitchen Appliances",
    items: [
      { name: "Gas stoves", imageUrl: "/images/gas-stove.jpg" },
      { name: "Frying pan", imageUrl: "/images/frying-pan.jpg" },
      { name: "Kettle", imageUrl: "/images/kettle.jpg" },
      { name: "Casserole", imageUrl: "/images/casserole.jpg" },
      { name: "Cooker", imageUrl: "/images/cooker.jpg" },
      { name: "Juice mixer", imageUrl: "/images/juice-mixer.jpg" },
      { name: "Water bottle", imageUrl: "/images/water-bottle.jpg" },
      { name: "Steel vacuum flask", imageUrl: "/images/steel-vacuum-flask.jpg" },
      { name: "Tiffin", imageUrl: "/images/tiffin.jpg" },
      { name: "Plates", imageUrl: "/images/plates.jpg" },
      { name: "Apical knife", imageUrl: "/images/apical-knife.jpg" },
      { name: "Cutting board", imageUrl: "/images/cutting-board.jpg" },
      { name: "Coconut scraper", imageUrl: "/images/coconut-scraper.jpg" },
      { name: "Knife and peeler", imageUrl: "/images/knife-and-peeler.jpg" },
    ],
  },
];

export default categoriesData;
