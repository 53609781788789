import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/Home/Home";
import "./App.css";
import Categories from "./pages/Categories/Categories";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <div className="app-text">
          <Routes>
            <Route exact path="/" Component={Home} />
            <Route path="/categories" Component={Categories} />
            <Route path="/about" Component={About} />
            <Route path="/contact" Component={Contact} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
