import React from "react";

const CategoryItem = ({ name, imageUrl }) => {
  return (
    <li className="bg-white bg-opacity-20 rounded-lg overflow-hidden hover:bg-opacity-30 transition-all duration-300">
      <div className="aspect-w-1 aspect-h-1">
        <img src={imageUrl} alt={name} className="w-full h-full object-cover" />
      </div>
      <div className="p-4">
        <span className="text-gray-200 hover:text-green-300 transition-colors duration-300">
          {name}
        </span>
      </div>
    </li>
  );
};

export default CategoryItem;
