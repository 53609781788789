import React from "react";
import CategoryItem from "./CategoryItem";

const CategoryList = ({ title, items }) => {
  return (
    <div className="mb-8 bg-white bg-opacity-10 rounded-lg overflow-hidden backdrop-filter backdrop-blur-lg">
      <div className="text-2xl font-semibold pb-2 mb-4 ps-6 pt-4 text-white border-b border-green-400">
        {title}
      </div>
      <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 p-6">
        {items.map((item, index) => (
          <CategoryItem key={index} name={item.name} imageUrl={item.imageUrl} />
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;