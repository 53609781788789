import React from "react";
import CategoryList from "../../components/Category/CategoryList";
import categoriesData from "../../data/categoriesData";

const Categories = () => {
  return (
    <div className="bg-gradient-to-b from-green-600 to-green-800 min-h-screen py-12">
      <div className="container mx-auto p-4">
        <div className="flex items-center mb-8">
          <h1 className="text-3xl font-bold text-white">Shop by Category</h1>
        </div>
        <div className="bg-white bg-opacity-10 rounded-lg p-8 backdrop-filter backdrop-blur-lg">
          {categoriesData.map((category, index) => (
            <CategoryList
              key={index}
              title={category.title}
              items={category.items}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;