import React from "react";

const About = () => {
  return (
    <div className="bg-gradient-to-b from-green-600 to-green-800 min-h-screen flex items-center justify-center py-12">
  <div className="container mx-auto px-4">
    <div className="flex items-center mb-8">
      <h2 className="text-3xl font-bold text-white">About Us</h2>
    </div>
    <div className="text-lg text-gray-200 leading-relaxed mb-8">
      <p className="mb-4">
        Established in 2016, Kudla Marketing Wholesale is a premier supplier of high-quality goods, dedicated to serving retailers, businesses, and institutions. Our mission is to provide a vast selection of products at competitive prices, ensuring our clients have access to the best possible deals.
      </p>
      <p>
        Kudla Marketing, as a wholesale warehouse, focuses on distributing various home appliance brands, including Willet TV and Green Chef Home, Uniko and Good Day brands.
      </p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div>
        <h3 className="text-xl font-semibold text-white mb-4">Willet TV and Green Chef Home</h3>
        <p className="text-gray-200 mb-4">
          Willet TV is a prominent brand offering a variety of televisions, ranging from basic models to advanced smart TVs with the latest technologies like LED and OLED. Green Chef Home Appliances includes a comprehensive selection of kitchen and home appliances, such as blenders, mixers, microwaves, and air fryers, catering to the needs of modern households.
        </p>
      </div>
      <div>
        <h3 className="text-xl font-semibold text-white mb-4">Uniko and Good Day Home Products</h3>
        <p className="text-gray-200 mb-4">
          Good Day Home Products focuses on everyday household items like water bottles and tiffin boxes, essential for daily use by families, students, and professionals. Meanwhile, Uniko provides practical home utility products like drying stands and ironing stands, which are indispensable for laundry and household chores.
        </p>
      </div>
    </div>
  </div>
</div>
  );
};

export default About;
