import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <section className="bg-gradient-to-r from-green-600 to-green-800 text-white min-h-screen flex items-center">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Welcome to Kudla Marketing
          </h1>
          <p className="text-xl md:text-2xl mb-8">
            Discover the best deals on high-quality home and kitchen appliances.
          </p>
          <Link to="/categories">
            <button className="bg-white text-green-700 hover:bg-green-100 font-bold py-3 px-8 rounded-full transition duration-300 text-lg">
              Shop Now
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Home;