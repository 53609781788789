import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path
      ? "text-white font-bold"
      : "text-green-200 hover:text-white";
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-gradient-to-r from-green-600 to-green-800 shadow-md z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="logo">
          <Link to="/" className="text-2xl font-bold">
            <span className="text-white">Kudla</span>{" "}
            <span className="text-green-200">Marketing</span>
          </Link>
        </div>
        <nav>
          <ul className="flex space-x-6">
            <li>
              <Link
                to="/"
                className={`${isActive("/")} transition duration-300`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/categories"
                className={`${isActive("/categories")} transition duration-300`}
              >
                Shop by Category
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`${isActive("/about")} transition duration-300`}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`${isActive("/contact")} transition duration-300`}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
