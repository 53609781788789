import React from "react";

const ContactUs = () => {
  return (
    <div className="bg-gradient-to-b from-green-600 to-green-800 min-h-screen flex items-center justify-center py-12">
      <div className="container mx-auto px-4">
        <div className="flex items-center mb-8">
          <h2 className="text-3xl font-bold text-white">Contact Us</h2>
        </div>
        <div className="bg-white bg-opacity-10 rounded-lg p-8 backdrop-filter backdrop-blur-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="contact-info">
              <h3 className="text-xl font-semibold text-white mb-4">Contact Information</h3>
              <p className="text-gray-200 mb-2">
                <span className="font-medium">Email:</span>{" "}
                <a href="mailto:kudlamarketing@gmail.com" className="hover:text-green-300 transition-colors duration-300">
                  kudlamarketing@gmail.com
                </a>
              </p>
              <p className="text-gray-200 mb-4">
                <span className="font-medium">Owner:</span> Wilfred Felix Dsouza{" "}
                <a href="tel:9590966695" className="hover:text-green-300 transition-colors duration-300">
                  (9590966695)
                </a>
              </p>
              
              <h4 className="text-lg font-medium mb-2 text-white">Co-owners:</h4>
              <ul className="list-disc list-inside mb-4 ml-4 text-gray-200">
                <li>
                  Venkatesh{" "}
                  <a href="tel:9845399855" className="hover:text-green-300 transition-colors duration-300">
                    (9845399855)
                  </a>
                </li>
                <li>
                  Gladicia Shaina D'souza{" "}
                  <a href="tel:8296068326" className="hover:text-green-300 transition-colors duration-300">
                    (8296068326)
                  </a>
                </li>
              </ul>
              <p className="text-gray-200 mb-4">
                <span className="font-medium">Quality Checker:</span> Gladicia Shaina D'souza
              </p>
            </div>

            <div className="address">
              <h3 className="text-xl font-semibold text-white mb-4">Our Address</h3>
              <address className="not-italic text-gray-200">
                KUDLA MARKETING<br />
                10-2-77/1, 1st Floor, Swastic Compound,<br />
                Mpt Road, Bunder,<br />
                Mangaluru,<br />
                Karnataka
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;